var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"padding-top":"0"},attrs:{"grid-list-xl":"","fluid":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"md12":""}},[_c('material-card',{attrs:{"title":_vm.$t('templateEmail.list'),"color":"green","flat":"","full-width":""}},[[(!_vm.isEntityDisabled())?_c('v-btn',{staticStyle:{"float":"right"},attrs:{"color":"success","dark":""},on:{"click":_vm.createNewTemplateEmail}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-text-box-plus-outline")]),_c('span',{staticStyle:{"text-transform":"none"}},[_vm._v(_vm._s(_vm.$t('templateEmail.createNew')))])],1):_vm._e(),_c('v-btn',{staticStyle:{"float":"right"},attrs:{"color":"error","dark":""},on:{"click":_vm.onClose}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-close")]),_c('span',{staticStyle:{"text-transform":"none"}},[_vm._v(_vm._s(_vm.$t('common.close')))])],1)],_c('v-data-table',{attrs:{"no-data-text":_vm.$t('common.noDataAvailable'),"headers":_vm.templateEmailHeaders,"items":_vm.templateEmailList,"single-select":true,"hide-actions":""},scopedSlots:_vm._u([{key:"headerCell",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"text--darken-3 font-medium",domProps:{"textContent":_vm._s(_vm.$t(header.text))}})]}},{key:"items",fn:function(ref){
var item = ref.item;
return _vm._l((_vm.templateEmailHeaders),function(header,index){return _c('td',{key:header.value + index},[(header.value == 'usedDefault')?_c('div',[(!item[header.value])?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"error","dark":""}},on),[_vm._v("mdi-close-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('evoucher.state.inactive')))])]):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"success","dark":""}},on),[_vm._v("mdi-check-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('evoucher.state.active')))])])],1):(header.value == 'actions')?_c('div',[(!item['usedDefault'])?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",staticStyle:{"color":"#ffffff"},attrs:{"disabled":_vm.isEntityDisabled(),"fab":"","small":"","color":"indigo"},on:{"click":function($event){return _vm.useThisTemplate(item['id'])}}},on),[_c('v-icon',[_vm._v("mdi-check")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("templateEmail.usedThisTemplate")))])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",staticStyle:{"color":"#ffffff"},attrs:{"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.updateTemplateEmail(item['id'])}}},on),[_c('v-icon',[_vm._v("mdi-square-edit-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("common.update")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",staticStyle:{"color":"#ffffff"},attrs:{"disabled":_vm.isEntityDisabled(),"fab":"","small":"","color":"error"},on:{"click":function($event){return _vm.onShowModalConfirmDelete(item['id'])}}},on),[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("common.delete")))])])],1):_c('span',[_vm._v(_vm._s(item[header.value]))])])})}}])})],2)],1)],1),_c('pagination',{attrs:{"total":_vm.paginate.totalPage,"current-page":_vm.paginate.currentPage,"row-per-page":_vm.paginate.rowPerPage},on:{"onPageChange":_vm.onPageChange}}),_c('confirm-modal',{ref:"confirmModal",attrs:{"title":_vm.confirmModalTitle},on:{"onConfirm":_vm.onConfirmDeleted}}),_vm._l((_vm.GET_SNACK_BAR),function(snackbar){return _c('snack-bar',{key:snackbar.id,attrs:{"item":snackbar}})}),_c('loading-bar',{attrs:{"is-loading":_vm.isLoading}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }